<template>
  <AppBar>
    <div class="record">
      <div class="top_box">
        <div class="left_box">
          <el-button
            type="text"
            icon="el-icon-back"
            @click="$router.go(-1)"
          ></el-button>
          <span>
            项目<b class="projectName"> {{ $route.params.title }} </b
            >的全部沟通记录
          </span>
        </div>
        <div class="rigth_box">
          <el-input
            placeholder="搜索沟通记录..."
            v-model.trim="search"
            size="small"
            @change="intBlur"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
      </div>
      <div class="main">
        <el-timeline>
          <el-timeline-item
            v-for="(item, idx) of feedbackList"
            :key="idx"
            placement="top"
          >
            <!-- :timestamp="getTime(item.create_time)" -->

            <div class="information">
              <!-- <el-avatar
                size="small"
                :src="
                  item.thumb
                    ? '//ghf.site.yscase.com' + item.thumb
                    : '//cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png'
                "
              ></el-avatar> -->
              <!-- <span>{{
                item.name +
                  (item.role_id
                    ? "(" + $common.position[item.role_id - 1] + ")"
                    : "")
              }}</span> -->

              <div>
                <div
                  :class="
                    idx == 0 ? 'background background-active' : 'background'
                  "
                >
                  {{item.design_id? `第${ projectIdList.indexOf(item.design_id) + 1}版反馈`: `第${ projectIdList.indexOf(item.id) + 1}版提交`}}
                </div>
                <!-- <span>{{
                  item.name +
                    (item.role_id
                      ? "(" + $common.position[item.role_id - 1] + ")"
                      : "")
                }}</span> -->
                <span>{{ item.descr }}</span>
                <span style="margin-left: 20px">{{
                  getTime(item.create_time)
                }}</span>
              </div>
            </div>
            <el-card>
              <p v-if="item.worker" class="detailedMsg">
                <span>{{item.worker.name}}</span>提交了新的设计稿
              </p>
              <p class="content-box" v-html="item.msg">
                
                <el-button
                  v-if="item.alink"
                  type="text"
                  class="lianjie"
                  icon="el-icon-paperclip"
                  @click="copyAlink(item.alink)"
                ></el-button>

                <a
                  :href="'//ghf.site.yscase.com/' + item.pic"
                  :download="'//ghf.site.yscase.com/' + item.pic"
                  v-if="item.pic"
                >
                  <el-image
                    v-if="
                      !(
                        item.pic.indexOf('.png') != -1 ||
                        item.pic.indexOf('.jpg') != -1
                      )
                    "
                    class="lianjie"
                    style="width: 100px; height: 100px"
                    :src="imgsrc({ name: item.pic, url: item.pic })"
                  >
                  </el-image>
                  <el-image
                    v-if="
                      item.pic.indexOf('.png') != -1 ||
                      item.pic.indexOf('.jpg') != -1
                    "
                    class="lianjie"
                    style="width: 100px; height: 100px"
                    :src="
                      '//ghf.site.yscase.com/' +
                      imgsrc({ name: item.pic, url: item.pic })
                    "
                  >
                  </el-image>
                </a>
                <span v-if="item.pics">
                  <a
                    v-for="(items,idx1) in item.pics.split(',')"
                    :href="'//ghf.site.yscase.com/' + items"
                    :download="'//ghf.site.yscase.com/' + items"
                    :key="idx1"
                  >
                    <el-image
                      v-if="
                        !(
                          items.indexOf('.png') != -1 ||
                          items.indexOf('.jpg') != -1
                        )
                      "
                      class="lianjie"
                      style="width: 100px; height: 100px"
                      :src="imgsrc({ name: items, url: items })"
                    >
                    </el-image>
                    <el-image
                      v-if="
                        items.indexOf('.png') != -1 ||
                        items.indexOf('.jpg') != -1
                      "
                      class="lianjie"
                      style="width: 100px; height: 100px"
                      :src="
                        '//ghf.site.yscase.com/' +
                        imgsrc({ name: items, url: items })
                      "
                    >
                    </el-image>
                  </a>
                </span>
              </p>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
  </AppBar>
</template>

<style lang="less" scoped>
@deep: ~">>>";
.record {
  width: 100%;
  height: 100%;
  position: relative;
}
@{deep}.top_box {
  display: flex;
  justify-content: space-between;
  line-height: 28px;
  .left_box {
    .projectName {
      color: #f8b849;
      font-weight: 400;
    }
    .el-button {
      padding: 0;
      color: #666;
    }
    span {
      margin-left: 20px;
    }
  }
  .rigth_box {
    text-align: right;
    .el-input {
      input {
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #acacac;
        background-color: #f2f2f2;
      }
    }
  }
}
.main {
  position: absolute;
  top: 55px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px 20px 0;
  overflow: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 32px;
  }
  // <!--修改 滚动条的 下面 的 样式-->
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  // <!--修改 滑块 -->
  &::-webkit-scrollbar-thumb {
    background-color: #ebebeb;
    border-radius: 10px;
    // border: 5px solid #000;
    border: 12px solid #fff;
  }
  @{deep}.el-timeline-item__timestamp.is-top {
    margin-bottom: 0;
    padding-top: 0;
  }
  @{deep}.el-timeline {
    .el-timeline-item {
      .el-timeline-item__node {
        background-color: #afafaf;
      }
      .information > div {
        display: flex;
        align-items: center;
        font-size: 16px;
      }
      .information span {
        line-height: 28px;
        color: #afafaf;
        font-size: 16px;
      }
      .feedbackMsg {
        color: #000;
        font-size: 16px;
        margin-top: 8px;
      }
      .content-box {
        display: flex;
        align-items: center;
      }
      .lianjie {
        width: 100px;
        height: 100px;
        font-size: 50px;
        margin-left: 20px;
        .el-image__error {
          margin-left: 0;
        }
      }
      .el-timeline-item__content {
        position: relative;
        margin-bottom: 10px;
        &:after {
          position: absolute;
          bottom: -20px;
          left: 0;
          content: "- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -";
          overflow: hidden;
          width: 100%;
          text-align: left;
          height: 20px;
          font-size: 20px;
          color: rgb(175, 175, 175);
          // border-bottom: 1px dashed #000;
        }
      }
      .background {
        position: relative;
        width: 106px;
        height: 25px;
        background-color: rgb(175, 175, 175);
        margin-right: 16px;
        color: #fff;
        text-align: center;
        line-height: 25px;
        font-size: 16px;
        &:after {
          position: absolute;
          top: 50%;
          margin-top: -7px;
          right: -14px;
          content: "";
          border: 7px solid transparent;
          border-left-color: rgb(175, 175, 175);
          // width: 8px;
          // height: 14px;
          // background-color: rgb(175,175,175);
        }
      }
      .background-active {
        background-color: rgb(216, 151, 70);
        &:after {
          border-left-color: rgb(216, 151, 70);
        }
      }
      .head_img {
        position: absolute;
        top: -4px;
        left: 140px;
        font-size: 12px;
        line-height: 28px;
        color: #909399;
        display: flex;
        .el-avatar {
          margin-right: 10px;
        }
      }
      .el-card {
        margin-top: 15px;
        box-shadow: none;
        border: none;
        .el-card__body {
          padding: 0;
          .image {
            margin-top: 10px;
            padding-bottom: 10px;
            border-bottom: 1px dashed #ddd;
            display: flex;
            li {
              width: 135px;
              margin-right: 14px;
              //   height: 84px;
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<script>
var that  = null;
import AppBar from "../../components/AppBar";
export default {
  data() {
    return {
      search: "",
      feedbackList: [],
      msg: "",
      projectIdList: []
    };
  },
  methods: {
    // 搜索全部反馈记录
    intBlur() {
      that.$common.getInterface(
        "/api/v1.designs/getFeedback",
        {project_id:that.$route.params.id,findkey:that.search},
        that,
        res => {
          console.log(res);
          this.feedbackList = res.data;
        }
      );
      console.log(1);
    },
    // 图片
    imgsrc(file) {
      let num = file.name.lastIndexOf(".");
      let type = file.name.substr(num);
      if (type === ".jpg" || type === ".png") {
        return file.url;
      } else if (type === ".rar" || type === ".zip") {
        return require("../../assets/img/yasuobao.png");
      } else if (
        type === ".doc" ||
        type === ".docx" ||
        type === ".word" ||
        type === ".rtf"
      ) {
        return require("../../assets/img/word.png");
      } else if (type === ".xls" || type === ".xlsx") {
        return require("../../assets/img/xls.png");
      } else if (type === ".ppt" || type === ".pptx") {
        return require("../../assets/img/ppt.png");
      } else if (type === ".pdf") {
        return require("../../assets/img/pdf.png");
      } else if (type === ".txt") {
        return require("../../assets/img/txt.png");
      }
    },
    getTime(date) {
      return this.$common.judgeDate(date);
    },
    copyAlink(alinkText) {
      var input = document.createElement("input"); // js创建一个input输入框
      input.value = alinkText; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input); // 将输入框暂时创建到实例里面
      input.select(); // 选中输入框中的内容
      document.execCommand("Copy"); // 执行复制操作
      document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
      setmas("复制成功", "success", this);
      // this.$message.success("复制成功");
      console.log(alinkText);
    },
  },
  created() {
    that = this;
    that.$common.getInterface(
      "/api/v1.projects/get_full",
      {project_id:that.$route.params.id},
      that,
      res => {
        console.log(res,"resres");
        res.data.forEach(item => {
          this.projectIdList.push(item.id);
          this.feedbackList.push(item);
          if(item.msg_list.length != 0) {
            this.feedbackList = this.feedbackList.concat(item.msg_list);
          }
        })
        this.feedbackList = this.feedbackList.reverse();
      // this.feedbackList = res.data;
      }
    );
    // this.$common.getInterface(
    //   "/api/v1.designs/getFeedback",
    //   { project_id: this.$route.params.id },
    //   this,
    //   (res) => {
    //     // console.log(res);
    //     this.feedbackList = res.data;
    //     console.log(this.feedbackList);
    //     // this.$router.push({ name: "Audit" });
    //   }
    // );
  },
  components: {
    AppBar,
  },
};
// 提醒弹出框
function setmas(msg, type, vm) {
  vm.$message({
    message: msg,
    type: type,
    customClass: "popup",
  });
}
</script>
<style>
.popup {
  width: 400px;
  height: 70px;
  font-size: 26px;
  font-weight: 700;
  position: absolute !important;
  top: 45% !important;
  margin-top: -35px !important;
}
.detailedMsg {
  margin-bottom: 20px;
}
.detailedMsg span{
  color: rgb(248,188,87);
}
</style>