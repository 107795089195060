var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppBar',[_c('div',{staticClass:"record"},[_c('div',{staticClass:"top_box"},[_c('div',{staticClass:"left_box"},[_c('el-button',{attrs:{"type":"text","icon":"el-icon-back"},on:{"click":function($event){return _vm.$router.go(-1)}}}),_c('span',[_vm._v(" 项目"),_c('b',{staticClass:"projectName"},[_vm._v(" "+_vm._s(_vm.$route.params.title)+" ")]),_vm._v("的全部沟通记录 ")])],1),_c('div',{staticClass:"rigth_box"},[_c('el-input',{attrs:{"placeholder":"搜索沟通记录...","size":"small"},on:{"change":_vm.intBlur},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}},[_c('i',{staticClass:"el-input__icon el-icon-search",attrs:{"slot":"prefix"},slot:"prefix"})])],1)]),_c('div',{staticClass:"main"},[_c('el-timeline',_vm._l((_vm.feedbackList),function(item,idx){return _c('el-timeline-item',{key:idx,attrs:{"placement":"top"}},[_c('div',{staticClass:"information"},[_c('div',[_c('div',{class:idx == 0 ? 'background background-active' : 'background'},[_vm._v(" "+_vm._s(item.design_id? ("第" + (_vm.projectIdList.indexOf(item.design_id) + 1) + "版反馈"): ("第" + (_vm.projectIdList.indexOf(item.id) + 1) + "版提交"))+" ")]),_c('span',[_vm._v(_vm._s(item.descr))]),_c('span',{staticStyle:{"margin-left":"20px"}},[_vm._v(_vm._s(_vm.getTime(item.create_time)))])])]),_c('el-card',[(item.worker)?_c('p',{staticClass:"detailedMsg"},[_c('span',[_vm._v(_vm._s(item.worker.name))]),_vm._v("提交了新的设计稿 ")]):_vm._e(),_c('p',{staticClass:"content-box",domProps:{"innerHTML":_vm._s(item.msg)}},[(item.alink)?_c('el-button',{staticClass:"lianjie",attrs:{"type":"text","icon":"el-icon-paperclip"},on:{"click":function($event){return _vm.copyAlink(item.alink)}}}):_vm._e(),(item.pic)?_c('a',{attrs:{"href":'//ghf.site.yscase.com/' + item.pic,"download":'//ghf.site.yscase.com/' + item.pic}},[(
                    !(
                      item.pic.indexOf('.png') != -1 ||
                      item.pic.indexOf('.jpg') != -1
                    )
                  )?_c('el-image',{staticClass:"lianjie",staticStyle:{"width":"100px","height":"100px"},attrs:{"src":_vm.imgsrc({ name: item.pic, url: item.pic })}}):_vm._e(),(
                    item.pic.indexOf('.png') != -1 ||
                    item.pic.indexOf('.jpg') != -1
                  )?_c('el-image',{staticClass:"lianjie",staticStyle:{"width":"100px","height":"100px"},attrs:{"src":'//ghf.site.yscase.com/' +
                    _vm.imgsrc({ name: item.pic, url: item.pic })}}):_vm._e()],1):_vm._e(),(item.pics)?_c('span',_vm._l((item.pics.split(',')),function(items,idx1){return _c('a',{key:idx1,attrs:{"href":'//ghf.site.yscase.com/' + items,"download":'//ghf.site.yscase.com/' + items}},[(
                      !(
                        items.indexOf('.png') != -1 ||
                        items.indexOf('.jpg') != -1
                      )
                    )?_c('el-image',{staticClass:"lianjie",staticStyle:{"width":"100px","height":"100px"},attrs:{"src":_vm.imgsrc({ name: items, url: items })}}):_vm._e(),(
                      items.indexOf('.png') != -1 ||
                      items.indexOf('.jpg') != -1
                    )?_c('el-image',{staticClass:"lianjie",staticStyle:{"width":"100px","height":"100px"},attrs:{"src":'//ghf.site.yscase.com/' +
                      _vm.imgsrc({ name: items, url: items })}}):_vm._e()],1)}),0):_vm._e()],1)])],1)}),1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }